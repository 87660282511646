import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "jsonwebtoken";
import * as microsoftTeams from "@microsoft/teams-js";
import config from "./config.ts";

interface MyJwtPayload extends JwtPayload {
  email?: string;
}

// Code that works for our multi-tenant configuration
//  as we can specify the tenant in the call
// Note about slient: false
//  must be set to false so that Teams will ask for the user permission if needed
//  If silent:true, the following error could occure:
//    Error: resourceRequiresConsent
async function getAuthToken() {
  try {
      const token = microsoftTeams.authentication.getAuthToken({
        silent: false,
        tenantId: String(config.appTenantId)
      });
      
      // Use the token for API calls
      return token;
  } catch (error) {
      throw error;
  }
}

// Code to get the token with getClientSideToken(), working for the Web and App versions of Teams
export const getTokenForSSO = async (): Promise<String | null> => {
    var clientSideToken = ''
    try {
      // To debug the variable
      let token = await  getAuthToken();
      clientSideToken = String(token)
    } catch (error) {
      console.log("Exception in getAuthToken()");
      console.log(error) ;
      // TODO temporary to identify why the getToken fails
      // return String(error)
      throw error
    }    
    
    const newIdToken = jwtDecode<MyJwtPayload>(
      clientSideToken
    );

    const stringToken = JSON.stringify(newIdToken);
    const parsedToken = newIdToken ? JSON.parse(stringToken) : null;
    localStorage.setItem("userEmail", parsedToken.email);
    localStorage.setItem("userGivenName", parsedToken.given_name);
    localStorage.setItem("userFamilyName", parsedToken.family_name);

    return clientSideToken;
};

export async function getContext() {
  const context = await microsoftTeams.app.getContext();
  localStorage.setItem("teamName", context.team?.displayName || "");
  localStorage.setItem("teamId", context.team?.internalId || "");
  localStorage.setItem("channelName", context.channel?.displayName || "");
  localStorage.setItem("channelId", context.channel?.id || "");
  localStorage.setItem("groupId", context.team?.groupId || "");
}

export async function clearCache() {
  localStorage.clear();
  console.log("localStorage cleared");
}
