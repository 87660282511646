import React, { useState, useEffect } from "react";
import { Image, Button } from "@fluentui/react-components";
import { TextField } from "@fluentui/react";
import * as microsoftTeams from "@microsoft/teams-js";
import { getBoardParent } from "../api/Board.api";
import { getContext, clearCache } from "../api/authUtils.api";
import { getUser } from "../api/User.api";
import { getTokenForSSO } from "../api/authUtils.api";

interface RenderModifyBoardPageProps {
  goToTabSetup: () => void;
}

const RenderModifyBoardPage: React.FC<RenderModifyBoardPageProps> = ({
  goToTabSetup,
}) => {
  const [textFieldValue, setTextFieldValue] = useState("");

  useEffect(() => {
    const initialize = async () => {
      // Ensure that the context information is correct/updated
      await clearCache() ; // Added for new code, was done in getIdToken()
      await getContext();
    };

    initialize();
  }, []);

  microsoftTeams.pages.config.registerOnSaveHandler(async (saveEvent) => {
    const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
    const suggestedDisplayName = localStorage.getItem("boardName") || "";
    // The boardUrl was given by the user
    const boardUrl = textFieldValue
     // Use the boardUrl as entityId, websiteUrl and contentUrl
    microsoftTeams.pages.config.setConfig({
      suggestedDisplayName: suggestedDisplayName,
      entityId: ``,
      contentUrl: `${baseUrl}/tab`,
      websiteUrl: `${boardUrl}`,
    });

    // Save the boardUrl in localStorage
    localStorage.setItem("boardUrl", textFieldValue);

    // Finish configuration and navigate to Tab.tsx
    saveEvent.notifySuccess();
  });

  return (
    <div className="tab-conf">
      <div className="logo-tab-conf py-30">
        <Image alt="logo kinaps" src="logo.png" />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1>Open a pre-existing Board</h1>
      </div>

      <div className="display-column input-tab-config py-30">
        <TextField
          placeholder="Insert Board URL here..."
          onChange={async (e, newValue) => {
            setTextFieldValue(newValue || "");
            // Check the validity of the URL by checking if it starts with the correct URL and if the ID is 24 characters long
            let customBoardID = "";
            if (
              newValue &&
              newValue.startsWith(
                (process.env.REACT_APP_KINAPS_BASE_BOARD_URL || "")
              ) &&
              newValue.split("/").pop()?.length === 24
            ) {
              setTextFieldValue(newValue || "");
              customBoardID = newValue.slice(-24);
              const boardInfo = await getBoardParent(customBoardID);
              console.log("boardInfo : ", boardInfo);

              // Retrieve the token
              console.log("getting id token");
              let token = await getTokenForSSO();
              if (!token) {
                console.log("Error while retrieving the token")
                microsoftTeams.pages.config.setValidityState(false);
                return ;
              }
              
              const userInfo = await getUser(token);
              const userId = userInfo._id;
              console.log("userId : ", userId);
              const boardOwner = boardInfo.owner;
              console.log("boardOwner : ", boardOwner);

              // Only open Board if owner is the current user
              if (userId === boardOwner) {
                // Save board name for Teams Tab name and board URL for Tab iFrame and browser tab
                const boardName = boardInfo.name;
                localStorage.setItem("boardName", boardName);
                localStorage.setItem("boardUrl", newValue || "");
                microsoftTeams.pages.config.setValidityState(true);
              } else {
                microsoftTeams.pages.config.setValidityState(false);
              }
              microsoftTeams.pages.config.setValidityState(true);
            } else {
              microsoftTeams.pages.config.setValidityState(false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default RenderModifyBoardPage;
