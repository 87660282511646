import {
  getFolderByNameAndOwner,
  getFolderParent,
  getRoomByNameAndParent,
  getRoomParent,
} from "./Folder.api";
import { getUser } from "./User.api";
import { getBoardParent } from "./Board.api";
import { json } from "stream/consumers";
import { getContext, getTokenForSSO } from "./authUtils.api";
import config from "./config.ts";

function isJSON(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

//Share permissions lorsque qqn ouvre tab pré-existant (root -> 4, folders -> 7, room -> 10 + board -> 12)

// folder-root
const ShareFolderUserPermissions = async (token: String, folderId: string, userId: string) => {
  // Expected Result :
  /*
    {
      "status": true,
      "failed": []
    }
  */

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  var raw = JSON.stringify([
    {
      folder_id: folderId,
      user_id: userId,
      permission: "share",
    },
  ]);

  console.log("ShareFolderUserPermissions raw : ", raw);

  const response = await fetch(
    config.kinapsAPIUrl + "folders/users",
    {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as RequestRedirect,
      credentials: "include" as RequestCredentials,
    }
  );
  const responseText = await response.text();
  console.log("ShareFolderUserPermissions response : ", responseText);

  if (!response.ok) {
    console.error(
      "Error sharing folder user permissions",
      response.status,
      response.statusText
    );
    throw new Error(`HTTP error ${response.status}`);
  } else if (responseText.includes("Folder or")) {
    console.error("Error sharing folder user permissions", responseText);
    return responseText;
  } else if (responseText.includes("is not defined")) {
    return responseText;
  }

  // Return JSON if possible, else return responseText
  if (isJSON(responseText)) {
    const result = JSON.parse(responseText);
    return result;
  } else {
    return responseText;
  }
};



// room
const ShareRoomUserPermissions = async (token: String, roomId: string, userId: string) => {
  // Expected Result :
  /*
    [
        {
            "id": "65329c1607df826466a09a9b",
            "room_id": "6532538485045291d62901d9",
            "user_id": "6531469585045291d62900d3",
            "role_type": "moderator"
        },
        {
            "id": "65329c1607df826466a09a9c",
            "room_id": "6532538485045291d62901d9",
            "user_id": "6531472d85045291d62900ef",
            "role_type": "participant"
        }
    ]
  */

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  // TODO: Fabian: the TDA code was adding both role_type in the JSON, this is currently under clarification
  // {
  //   room_id: roomId,
  //   user_id: userId,
  //   role_type: "moderator",
  // },

  var raw = JSON.stringify([
    {
      room_id: roomId,
      user_id: userId,
      role_type: "participant",
    },
  ]);

  console.log("ShareRoomUserPermissions raw : ", raw);

  const response = await fetch(
    config.kinapsAPIUrl + "rooms/users",
    {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as RequestRedirect,
      credentials: "include" as RequestCredentials,
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const textResult = await response.text();
  const jsonResult = JSON.parse(textResult);
  console.log("ShareRoomUserPermissions response : ", jsonResult);
  console.log("ShareRoomUserPermissions response : ", textResult);
  return jsonResult;
};

//board
const ShareBoardUserPermissions = async (token: String, boardId: string, userId: string) => {
  // Expected Result :
  /*
    {
      "status": true,
      "failed": []
    }
  */
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  var raw = JSON.stringify([
    {
      board_id: boardId,
      user_id: userId,
    },
  ]);

  const response = await fetch(
    config.kinapsAPIUrl + "boards/users",
    {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as RequestRedirect,
      credentials: "include" as RequestCredentials,
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

// Permissions must be check on all levels:
//  board, room (if multilayer), folder, root folder
const SharePermissions = async (boardId: string) => {
  // Retrieve the token
  console.log("SharePermissions() - getting id token");
  let token = await getTokenForSSO();
  if (!token) {
    console.log("Error while retrieving the token for Kinaps API calls")
    return false;
  }  

  // Get User ID
  const userInfo = await getUser(token);
  if (!userInfo._id) {
    console.log("SharePermissions() - getUser failed - abort!");
    return false;
  }

  localStorage.setItem("userId", userInfo._id);

  // The different getBoardParent(), etc. can throw an exeption
  try {
    // 1. Board permissions
    // Check if the user is the owner or in the board "users" list -> if yes, nothing to do 
    const boardParent = await getBoardParent(boardId);

    // Memorise the folderID for next operation after handling the room in case of multilayer board
    var folderId = boardParent.parent ;

    console.log("SharePermissions() - Board permission handling");

    if (boardParent.owner == userInfo._id) {
      // user is the owner, nothing to do
      console.log("SharePermissions() - Board permission: user is the owner");
    } else {
      // User is not the owner, check if he already has the permission: if its ID is already in the "users" array
      console.log("SharePermissions() - Board permission: user is not the owner - check if userID already in users array");
      // "users" is an array, check with .includes
      if (boardParent.users && !boardParent.users.includes(userInfo._id)) {
        console.log("SharePermissions() - Board permission: user do not have the rights, adding") ;
        // Add the user to the board
        const shareBoardResult = await ShareBoardUserPermissions(
          token,
          boardId,
          userInfo._id
        );
        // If sharing board permissions failed, return false
        if (shareBoardResult.status === true) {
          console.log("SharePermissions() - Board permission: user added SUCCESS");
        } else {
          console.log("SharePermissions() - Board permission:user added ERROR");
          return false ;
        }   
      } else {
        console.log("SharePermissions() - Board permission: user is already in users array");
      }
    }

    // 2. check if the parent is a folder (singlelayer) or room (multilayer)
    console.log("SharePermissions() - Checking if multi layer board");

    if (boardParent.room != null) {
      console.log("SharePermissions() - Multi layer board - Handle room permissions");

      // It seems that getBoardParent() returns a JSON, but not getRoomParent()
      const roomParentInfo = await getRoomParent(boardParent.parent);
      const roomParentInfoJson = JSON.parse(roomParentInfo);

      if (roomParentInfoJson.owner == userInfo._id) {
        // user is the owner, nothing to do
        console.log("SharePermissions() - Room permission: user is the owner");
      } else {
        // User is not the owner, check if he already has the permission: if its ID is already in the "users" array
        console.log("SharePermissions() - Room permission: user is not the owner - check if userID already in users array");
        // users is an array of object:
        // "users": [
        //   {
        //     "id": "665eed21c470eb2a737863ff",
        //     "user": "65b2683fdeb5d11ff65f0221",
        //     "role": "participant"
        // }
        if (roomParentInfoJson.users && !roomParentInfoJson.users.find((person: { user: any; }) => person.user === userInfo._id)) {
          const shareRoomResult = await ShareRoomUserPermissions(
            token,
            boardParent.room,
            userInfo._id
          );

          // If sharing Room permissions failed, return false
          if ( !shareRoomResult.find((result: { room_id: any; }) => result.room_id === boardParent.room)) {
            console.log("SharePermissions() - Room permission:user added ERROR");
            return false ;
          } else {
            console.log("SharePermissions() - Room permission: user added SUCCESS");
          }   
        } else {
          console.log("SharePermissions() - Room permission: user is already in users array");
        }
        }

        // Update the folder to handle, which is the room's parent
        folderId = roomParentInfoJson.parent ;
    } 

    var levels = 0 ;

    // Go on with the folder and root folder
    // 3. Handle the parent folder (parent of the board in case of single layer, parent of the room in case of multilayer)
    while (folderId && levels < 2)
      {
      if (levels == 0) {
        console.log("SharePermissions() - Handling parent folder");
      } else {
        console.log("SharePermissions() - Handling parent of parent folder");
      }
            
      const folderParent = await getFolderParent(folderId);
      const folderParentJson = JSON.parse(folderParent);

      if (folderParentJson.owner == userInfo._id) {
        // user is the owner, nothing to do
        console.log("SharePermissions() - Folder permission: user is the owner");
      } else {
        // User is not the owner, check if he already has the permission: if its ID is already in the "users" array
        console.log("SharePermissions() - Folder permission: user is not the owner - check if userID already in 'sharedWithUsers' array");
        // "sharedWithUsers" is an array, check with .includes
        if (folderParentJson.sharedWithUsers && !folderParentJson.sharedWithUsers.includes(userInfo._id)) {
          const shareSubFolderResult = await ShareFolderUserPermissions(
            token,
            folderId,
            userInfo._id
          );
          // Continue only if sharing subfolder permissions was successful
          if (shareSubFolderResult.status === true) {
            console.log("SharePermissions() - Folder permission: user added SUCCESS");
          } else {
            console.log("SharePermissions() - Folder permission:user added ERROR");
            return false ;
          }   
        } else {
          console.log("SharePermissions() - Folder permission: user is already in 'sharedWithUsers' array");
        }
      }

      folderId = folderParentJson.parent;
      levels = levels + 1;
      }

    return true ;
  }   catch (error) {
    console.log("SharePermissions() - An Exception was raised: " + error);
    return false ;
  }

}

const SharePermissionsDTA = async (boardId: string) => {
  // Retrieve the token
  console.log("SharePermissions - getting id token");
  let token = await getTokenForSSO();
  if (!token) {
    console.log("Error while retrieving the token for Kinaps API calls")
    return false;
  }  

  // Get User ID for all permissions
  const userInfo = await getUser(token);
  localStorage.setItem("userId", userInfo._id);

  // SHARE BOARD PERMISSIONS
  const shareBoardResult = await ShareBoardUserPermissions(
    token,
    boardId,
    localStorage.getItem("userId") || ""
  );
  // If sharing board permissions failed, return false
  if (shareBoardResult.status === true) {
    console.log("Board permissions shared");
    // Test if board is single layer or multi layer
    const boardParent = await getBoardParent(boardId);
    console.log("boardParent", boardParent);

    // SINGLE LAYER BOARD
    if (boardParent.room == null) {
      console.log("Single layer board");
      // SHARE SUBFOLDER PERMISSIONS
      const shareSubFolderResult = await ShareFolderUserPermissions(
        token,
        boardParent.parent,
        localStorage.getItem("userId") || ""
      );
      // Continue only if sharing subfolder permissions was successful
      if (shareSubFolderResult.status === true) {
        console.log("Subfolder permissions shared");
        // Get parent folder ID
        const folderParent = await getFolderParent(boardParent.parent);
        // Parse folderParent into a JavaScript object
        const folderParentJson = JSON.parse(folderParent);
        // SHARE PARENT FOLDER PERMISSIONS
        const folderResult = await ShareFolderUserPermissions(
          token,
          folderParentJson.parent,
          localStorage.getItem("userId") || ""
        );
        if (folderResult.status === true) {
          console.log("Top Folder permissions shared");
          return true;
        } else {
          console.error("Error sharing top folder permissions");
          return false;
        }
      } else {
        console.error("Error sharing subfolder permissions");
        return false;
      }

      // MULTI LAYER BOARD
    } else {
      // Share permissions for room
      // TODO : Make sure this works as intended with Zhan 
      const shareRoomResult = await ShareRoomUserPermissions(
        token,
        boardParent.room,
        localStorage.getItem("userId") || ""
      );
      console.log("shareRoomResult", shareRoomResult);

      /* // Get Room parent via channel name + id
      await getContext();
      const channelName = localStorage.getItem("channelName");
      const channelId = localStorage.getItem("channelId");
      const channelFolderName = `${channelName}${channelId}`;

      // Get channel folder info
      const channelFolder = await getFolderByNameAndOwner(
        channelFolderName,
        localStorage.getItem("userId") || ""
      );
      const channelFolderJson = JSON.parse(channelFolder);
      console.log("channelFolderJson", channelFolderJson); */


      const roomParentInfo = await getRoomParent(boardParent.parent);
      const roomParentInfoJson = JSON.parse(roomParentInfo);
      console.log("roomParentInfoJson", roomParentInfoJson);

      // Share permissions for channel folder
      const shareChannelResult = await ShareFolderUserPermissions(
        token,
        roomParentInfoJson.parent,
        localStorage.getItem("userId") || ""
      );
      console.log("subFolderResult : ", shareChannelResult);
      if (shareChannelResult.status === true) {
        console.log("Multilayer Channel Folder permissions shared");

        // Get parent folder ID
        const channelFolderParent = await getFolderParent(roomParentInfoJson.parent);
        const channelFolderParentJson = JSON.parse(channelFolderParent);

        // Share permissions for parent folder
        const folderResult = await ShareFolderUserPermissions(
          token,
          channelFolderParentJson.parent,
          localStorage.getItem("userId") || ""
        );
        console.log("folderResult", folderResult);
        if (folderResult.status === true) {
          console.log("Multilayer Top Folder permissions shared");
          return true;
        } else {
          console.error("Error sharing multilayer top folder permissions");
          return false;
        }
      } else {
        console.error("Error sharing multilayer channel folder permissions");
        return false;
      }
    }
  } else {
    return false;
  }
};

export {
  ShareBoardUserPermissions,
  ShareFolderUserPermissions,
  ShareRoomUserPermissions,
  SharePermissions,
};
