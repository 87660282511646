import { getTokenForSSO } from "../api/authUtils.api";
import { createFolderStructure, getRoomByNameAndParent } from "./Folder.api";
import config from "./config.ts";

export const getBoardParent = async (boardID: string) => {
  // Expected result example (multiLayer) :
  /*
    {
        "id": "6554c411705bd8a290f8b70e",
        "room": "65532ffb6431681d78055750",
        "name": "board multilayer",
        "parent": "65532ffb6431681d78055750",
        "users": []
    }
  */

  // Expected result example (singleLayer) :
  /*
      {
          "id": "65533b9f6431681d7805575c",
          "room": null,
          "name": "test Borad singleLayer",
          "parent": "65532fe96431681d78055747",
          "users": []
      }
  */

  try {
    let token = await getTokenForSSO();
    if (!token) {
      console.log("Error while retrieving the token for Kinaps API calls")
      throw new Error(`Error while retrieving the token for Kinaps API calls!`);
    }    
  
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      credentials: "include",
      redirect: "follow",
    };

    const response = await fetch(config.kinapsAPIUrl + `boards?id=${boardID}`,
      requestOptions
    );
    if (!response.ok) {
      console.error(
        "Erreur lors de la récupération du board parent",
        response.status,
        response.statusText
      );
      throw new Error(`Erreur HTTP ${response.status}`);
    }

    const result = await response.json();
    if (result.error) {
      throw new Error(`Erreur getBoardParent: ${result.error}`);
    }

    console.log("getBoardParent result : ", result);
    return result;
  } catch (error) {
    console.error("Erreur lors de la récupération du board parent", error);
    throw error;
  }
};

// API Call to create a Board
export const createBoard = async (
  // Expected result example :
  /* 
    [
        {
            "id": "6532a11607df826466a09b08",
            "name": "BUSINESS STRATEGY & INNOVATION",
            "parent": "6532538485045291d62901d9"
        }
    ] 
  */

  parentFolderId: string,
  nameOfBoard: string
): Promise<any> => {
  let token = await getTokenForSSO();
  if (!token) {
    console.log("createBoard error  while retrieving the token for Kinaps API calls")
    throw new Error(`createBoard error while retrieving the token for Kinaps API calls!`);
  }   
 
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify([
    {
      name: `${nameOfBoard}`,
      parent: `${parentFolderId}`,
      roomLock: false,
      settings: {
        share: false,
      },
    },
  ]);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow" as RequestRedirect,
    credentials: "include" as RequestCredentials,
  };

  console.log("createBoard request : ", requestOptions);

  return fetch(config.kinapsAPIUrl + "boards", requestOptions)
    .then((response) => {
      console.log("createBoard response : ", response);
      return response.json();
    })
    .then((result) => {
      console.log("createBoard result : ", result);
      return result;
    })
    .catch((error) => {
      console.error("createBoard error : ", error);
      throw error;
    });
};

// Function creating a SingleLayer Board
export async function createSingleLayerBoard() {
  // Create Folder Structure (Team Folder + Channel Folder)
  if (await createFolderStructure()) {
    // Create Board using Channel Folder ID
    const boardFolderId = localStorage.getItem("channelFolderId");
    const nameOfBoard = localStorage.getItem("nameOfBoard");
    try {
      console.log("Creating SingleLayer Board");
      const boardInfo = await createBoard(
        boardFolderId || "",
        nameOfBoard || ""
      );
      // Set boardUrl for Tab iFrame and browser tab
      const boardId = boardInfo[0].id;
      localStorage.setItem("boardId", boardId || "");
      console.log("boardId : ", localStorage.getItem("boardId"));
      localStorage.setItem("boardUrl", config.kinapsBoardUrl + boardId || "");
      console.log("boardUrl : ", localStorage.getItem("boardUrl"));

      return true;
    } catch (error) {
      console.log("Error creating SingleLayer Board : ", error);
      return false;
    }
  } else {
    console.log("Error creating SingleLayer Board");
    return false;
  }
}

export const createRoom = async (parentFolderId: string): Promise<any> => {
  // Expected result example :
  /* 
    [
        {
            "id": "6532538485045291d62901d9",
            "name": "BUSINESS STRATEGY & INNOVATION",
            "description": "BUSINESS STRATEGY & INNOVATION",
            "parent": "65314b2b85045291d6290118",
            "isShared": true
        }
    ]
  */

  let token = await getTokenForSSO();
  if (!token) {
  console.log("createRoom error  while retrieving the token for Kinaps API calls")
  throw new Error(`createRoom error while retrieving the token for Kinaps API calls!`);
  }   

  const nameOfBoard = localStorage.getItem("nameOfBoard");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  console.log("name of created board", nameOfBoard);
  console.log("id of parent folder", parentFolderId);

  // Remark: when creating a room, share must be set to 'true' to avoid dispay errors in Kinaps
  var raw = JSON.stringify([
    {
      name: `${nameOfBoard}`,
      description: `${nameOfBoard}`,
      parent: `${parentFolderId}`,
      settings: {
        permissions: [
          {
            role: "moderator",
            permission: "full",
          },
          {
            role: "participant",
            permission: "share",
          },
        ],
        share: true,
      },
    },
  ]);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow" as RequestRedirect,
    credentials: "include" as RequestCredentials,
  };

  return fetch(config.kinapsAPIUrl + "rooms", requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log("createRoom response : ", response);
      return response.text();
    })
    .then((result) => {
      console.log("createRoom result", result);
      return result;
    })
    .catch((error) => {
      console.error("createRoom error : ", error);
      throw error;
    });
};

export async function createMultiLayerBoard() {
  // Create Folder Structure (Team Folder + Channel Folder)
  if (await createFolderStructure()) {
    // Check if the room already exists for the specific user
    const nameOfBoard = localStorage.getItem("nameOfBoard");
    const parentFolderId = localStorage.getItem("channelFolderId");
    console.log("nameOfBoard : ", nameOfBoard);
    console.log("parentFolderId : ", parentFolderId);
    let roomResult = await getRoomByNameAndParent(
      nameOfBoard || "",
      parentFolderId || ""
    );
    console.log("roomResult : ", roomResult);
    if (
      roomResult.includes("Room with name of ") &&
      roomResult.includes(" not found")
    ) {
      try {
        // If room doesn't exist, create room
        const roomFolderId = localStorage.getItem("channelFolderId");
        const createRoomResult = await createRoom(roomFolderId || "");
        console.log("createRoomResult : ", createRoomResult);
        // Parse string result to JSON
        const JSONcreateRoomResult = JSON.parse(createRoomResult);
        const roomName = JSONcreateRoomResult[0].name;
        const roomId = JSONcreateRoomResult[0].id;
        console.log("Room Name : ", roomName);
        console.log("Room ID : ", roomId);
        // Create Board using Channel Folder ID
        const boardInfo = await createBoard(roomId || "", nameOfBoard || "");
        console.log("MultiLayer Board created successfully !");
        localStorage.setItem("roomExists", "false");
        // Set boardUrl for Tab iFrame and browser tab
        const boardId = boardInfo[0].id;
        localStorage.setItem("boardId", boardId || "");
        console.log("boardId : ", localStorage.getItem("boardId"));
        localStorage.setItem("boardUrl", config.kinapsBoardUrl + boardId || "");
        console.log("boardUrl : ", localStorage.getItem("boardUrl"));
        return true;
      } catch (error) {
        console.log("Error creating MultiLayer Board : ", error);
        return false;
      }
    } else {
      console.log("Room already exists");
      localStorage.setItem("roomExists", "true");
      return false;
    }
  } else {
    console.log("Error creating MultiLayer Board");
    return false;
  }
}
