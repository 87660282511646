//import config from "../components/sample/lib/config.ts";
import config from "./config.ts";

export const getUser = async (
  token: String
): Promise<any> => {
  // Expected result example :
  /*
    {
      "_id": "6531472d85045291d62900ef",
      "profile_image": null,
      "login_name": "moodle_stud",
      "first_name": "Moodle",
      "last_name": "Stud",
      "display_name": "moodle_stud"
    }
  */
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    credentials: "include" as RequestCredentials,
    redirect: "follow" as RequestRedirect,
  };

  try {
    console.log("config.kinapsAPIUrl: ", config.kinapsAPIUrl)
    console.log("temporary debuging token: ", token)
    const response = await fetch(config.kinapsAPIUrl + "users/jwt",
      requestOptions
    );
    const result = await response.json();
    console.log("getUser result : ", result);
    return result;
  } catch (error) {
    console.log("Error in getUser : ", error);
    return;
  }
};