const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  apiName: process.env.REACT_APP_FUNC_NAME,
  kinapsAPIUrl: process.env.REACT_APP_KINAPS_API_URL,
  kinapsBoardUrl: process.env.REACT_APP_KINAPS_BASE_BOARD_URL,
};

export default config;
