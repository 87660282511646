import { getTokenForSSO } from "../api/authUtils.api";
import config from "./config.ts";

export const createRootFolder = async (name: string): Promise<any> => {
  // Expected result example :
  /*
    {
        "id": "6531489585045291d629010b", //save to DB
        "name": "slide.cyberlearn-mobile-dev.ch", //save to DB
        "description": "slide.cyberlearn-mobile-dev.ch",
        "isShared": false
    }
 */

  let token = await getTokenForSSO();
  if (!token) {
  console.log("createRootFolder error  while retrieving the token for Kinaps API calls")
  throw new Error(`createRootFolder error while retrieving the token for Kinaps API calls!`);
  }  
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  var raw = JSON.stringify([
    {
      name: `${name}`,
      description: `${name}`,
      settings: {
        share: false,
      },
    },
  ]);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow" as RequestRedirect,
    credentials: "include" as RequestCredentials,
  };

  try {
    const response = await fetch(config.kinapsAPIUrl + "folders",
      requestOptions
    );
    const result = await response.text();
    console.log("createRootFolder result : " + result);
    return result;
  } catch (error) {
    console.error("createRootFolder error", error);
    throw error;
  }
};

export const getFolderParent = async (folderId: string): Promise<any> => {
  // Expected result example :
  /*
    {
        "id": "655df89f66f7f7bfa11e4af4",
        "name": "Cours-4",
        "parent": "655df89f66f7f7bfa11e4af0",
        "description": "Cours-4",
        "users": []
    } 
*/

  let token = await getTokenForSSO();
  if (!token) {
  console.log("getFolderParent error  while retrieving the token for Kinaps API calls")
  throw new Error(`getFolderParent error while retrieving the token for Kinaps API calls!`);
  }  

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow" as RequestRedirect,
    credentials: "include" as RequestCredentials,
  };

  try {
    const response = await fetch(config.kinapsAPIUrl + `folders?id=${folderId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.text();
    console.log("getFolderParent result : " + result);
    return result;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const createFolderFromParent = async (
  name: string,
  parent: string
): Promise<any> => {
  // Expected result example :
  /*
    {
      "id": "65314b2b85045291d6290118", //save to DB
      "name": "Cours-3", //save to DB
      "parent": "6531489585045291d629010b", //save to DB
      "description": "Cours-3",
      "isShared": false
    } 
  */

  let token = await getTokenForSSO();
  if (!token) {
  console.log("createFolderFromParent error  while retrieving the token for Kinaps API calls")
  throw new Error(`createFolderFromParent error while retrieving the token for Kinaps API calls!`);
  }  

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify([
    {
      name: `${name}`,
      parent: `${parent}`,
      description: `${name}`,
      settings: {
        share: false,
      },
    },
  ]);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow" as RequestRedirect,
    credentials: "include" as RequestCredentials,
  };

  try {
    const response = await fetch(config.kinapsAPIUrl + "folders",
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.text();
    console.log("createFolderFromParent result : " + result);
    return result;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

// NOTE : getFolderByNameAndOwner returns a string, not a JSON object
// This is because the string is needed to check if the folder exists or not
// To use the result otherwise, use JSON.parse(result)
export const getFolderByNameAndOwner = async (
  name: string,
  owner: string
): Promise<any> => {
  // Expected result example :
  /*
    {
        "id": "6597bd294a9f52d5c8cbe596",
        "name": "test zhan",
        "parent": null,
        "description": null,
        "owner": "655df40bfbdf6abbeefa0d58",
        "sharedWithUsers": []
    }
*/

  let token = await getTokenForSSO();
  if (!token) {
  console.log("getFolderByNameAndOwner error  while retrieving the token for Kinaps API calls")
  throw new Error(`getFolderByNameAndOwner error while retrieving the token for Kinaps API calls!`);
  }   

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow" as RequestRedirect,
    credentials: "include" as RequestCredentials,
  };

  try {
    const response = await fetch(config.kinapsAPIUrl + `folderByNameAndOwner?name=${encodeURIComponent(
        name
      )}&owner=${encodeURIComponent(owner)}`,
      requestOptions
    );
    const result = await response.text();
    return result;
  } catch (error) {
    console.log("getFolderByNameAndOwner error : ", error);
    throw error;
  }
};

export async function getRoomByNameAndParent(
  name: string,
  parent: string
): Promise<any> {
  // Expected result example :
  /*
    {
        "roomFolder_id": "6532538485045291d62901da",
        "roomFolder_name": "BUSINESS STRATEGY & INNOVATION",
        "room_id": "6532538485045291d62901d9",
        "room_name": "BUSINESS STRATEGY & INNOVATION",
        "room_description": "BUSINESS STRATEGY & INNOVATION",
        "users": [
            {
                "id": "6532555385045291d6290228",
                "user": "6531472d85045291d62900ef",
                "role": "participant"
            },
            {
                "id": "65329be807df826466a09a95",
                "user": "6531469585045291d62900d3",
                "role": "moderator"
            },
            {
                "id": "65329be807df826466a09a96",
                "user": "6531472d85045291d62900ef",
                "role": "participant"
            }
        ]
    }
    */

  let token = await getTokenForSSO();
  if (!token) {
    console.log("createBoard error  while retrieving the token for Kinaps API calls")
    throw new Error(`createBoard error while retrieving the token for Kinaps API calls!`);
  }   

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow" as RequestRedirect,
    credentials: "include" as RequestCredentials,
  };

  return fetch(config.kinapsAPIUrl + `rooms/boards?name=${encodeURIComponent(
      name
    )}&parent=${encodeURIComponent(parent)}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log("getRoomByNameAndParent result : ", result);
      return result;
    })
    .catch((error) => {
      console.log("getRoomByNameAndParent error : ", error);
      throw error;
    });
}

export async function getRoomParent(roomId : string) {
  /*
    Expected result example :
    
    {
      "id": "65533db56431681d78055889",
      "name": "BUSINESS STRATEGY & INNOVATION 3",
      "description": "BUSINESS STRATEGY & INNOVATION 1",
      "parent": "6544eb87fb3e87ce83abcfc7",
      "users": []
    }
  */

  let token = await getTokenForSSO();
  if (!token) {
  console.log("getRoomParent error  while retrieving the token for Kinaps API calls")
  throw new Error(`getRoomParent error while retrieving the token for Kinaps API calls!`);
  }  

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow" as RequestRedirect,
    credentials : "include" as RequestCredentials
  };

  return fetch(config.kinapsAPIUrl + `rooms?id=${roomId}`,
    requestOptions
 )
    .then((response) => response.text())
    .then((result) => {
      console.log("getRoomParent result : ", result);
      return result || ""; // Ensure that an empty string is returned if result is undefined or null
    })
    .catch((error) => {
      console.log("error", error);
      return ""; // Return an empty string in case of an error
    });
}

export async function createFolderStructure() {
  console.log("Creating Folder Structure");

  // Retrieve ID and name of Channel and Team
  const channelName = localStorage.getItem("channelName");
  const channelId = localStorage.getItem("channelId");
  const teamName = localStorage.getItem("teamName");
  const teamId = localStorage.getItem("teamId");

  // Create Folder Name using Team Name + Team ID
  const teamFolderName = `${teamName}${teamId}`;
  const channelFolderName = `${channelName}${channelId}`;

  // Retrieve Owner's KINAPS ID
  const ownerId = localStorage.getItem("ownerID");

  // 1. Check if Team Folder using Team Name + Team ID exists
  try {
    const result = await getFolderByNameAndOwner(
      teamFolderName || "",
      ownerId || ""
    );
    console.log(
      "getFolderByNameAndOwner Team Folder creation result : ",
      result
    );
    // 1.1 If the folder doesn't exist, create it
    if (result && result.includes("Folder not found")) {
      const createFolderResult = await createRootFolder(teamFolderName || "");
      console.log("createFolderResult : ", createFolderResult);
      // Parse getFolderByNameAndOwner result from a string to JSON
      const JSONcreateFolderResult = JSON.parse(createFolderResult);
      console.log("JSONcreateFolderResult : ", JSONcreateFolderResult);
      localStorage.setItem("teamFolderId", JSONcreateFolderResult[0].id);
      console.log("teamFolderId set to: ", JSONcreateFolderResult[0].id);
    } else {
      // 1.2 If the folder exists, log it and save its ID
      console.log("Folder already exists");
      const JSONresult = JSON.parse(result);
      localStorage.setItem("teamFolderId", JSONresult.id);
      console.log("teamFolderId set to : ", JSONresult.id);
    }
  } catch (error) {
    console.log("Error : ", error);
    return false ;
  }

  // 2. Check if the Channel Folder exists using Channel Name + Channel ID
  try {
    const result = await getFolderByNameAndOwner(
      channelFolderName || "",
      ownerId || ""
    );
    console.log("getFolderByNameAndOwner Channel Folder result : ", result);
    // 2.1 If the folder doesn't exist, create it in the parent Team folder
    if (result && result.includes("Folder not found")) {
      console.log("Team Folder ID : ", localStorage.getItem("teamFolderId"));
      const createFolderResult = await createFolderFromParent(
        channelFolderName || "",
        localStorage.getItem("teamFolderId") || ""
      );
      // Parse getFolderByNameAndOwner result from a string to JSON
      const JSONcreateFolderResult = JSON.parse(createFolderResult);
      console.log("JSONcreateFolderResult : ", JSONcreateFolderResult);
      localStorage.setItem("channelFolderId", JSONcreateFolderResult[0].id);
      console.log("channelFolderId set to: ", JSONcreateFolderResult[0].id);
    } else {
      // 2.2 If the folder exists, log it and save its ID
      console.log("Folder already exists");
      const JSONresult = JSON.parse(result);
      localStorage.setItem("channelFolderId", JSONresult.id);
      console.log("channelFolderID : ", JSONresult.id);
    }
  } catch (error) {
    console.log("Error creating Channel folder : ", error);
    return false;
  }

  return true;
}
