import React from "react";
import { Image, Button } from "@fluentui/react-components";

interface RenderErrorPageProps {
  errorMessage: string;
}

const RenderError: React.FC<RenderErrorPageProps> = ({
  errorMessage,
}) => (
  <div className="tab-conf">
    <div className="logo-tab-conf py-30">
      <Image alt="logo kinaps" src="logo.png" />
    </div>
    AN ERROR OCCURED:<br />
    {errorMessage}
  </div>
);

export default RenderError;
