import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Input } from "@fluentui/react-components";
import { getContext, clearCache } from "../api/authUtils.api";
import { SharePermissions } from "../api/Permissions.api";
import * as microsoftTeams from "@microsoft/teams-js";
import { getTokenForSSO } from "../api/authUtils.api";

const Tab: React.FC = () => {
  // Récupérez l'e-mail du paramètre d'URL
  const email = localStorage.getItem("userEmail");
  var emailDomain = email
  if (email)  {
    emailDomain = email.slice(email.indexOf("@"));
  }

  // Debugging share permissions button and input
  // const [inputValue, setInputValue] = useState("");
  // const handleClick = () => {
  //   if (inputValue) {
  //     SharePermissions(inputValue);
  //   }
  // };

  const [customUrl, setCustomUrl] = useState("");

  const openInTab = async () => {
    let tabConfig =  microsoftTeams.pages.getConfig() ;
    let url = (await tabConfig).websiteUrl // contentUrl
    
    console.log("openInTab()")
    var email = localStorage.getItem("userEmail");

    if (!email) {
      let token = await getTokenForSSO();
      if (!token) {
        console.log("Error while retrieving the token to get the users' email - will open the board without SSO")
      }    
      email = localStorage.getItem("userEmail");
    }

    var emailDomain = email
    if (email)  {
      emailDomain = email.slice(email.indexOf("@"));
    }

    if (!url) {
       console.log("websiteUrl not found in current tab config - no board will be open in tab");
       return ;
    }

    // Before each board opening, handle the user's permissions
    // Get the boardID from the url -> the last part after the "/"
    var boardId = url.substring(url.lastIndexOf('/') + 1);
    var permResutl = await SharePermissions(boardId)
    if (!permResutl) {
        return ; // SharePermissions failed, a log has been displayed
      }

    if (emailDomain) {
       url += `?MS_SSO=${emailDomain}`; // encodeURIComponent(emailDomain)
    }
   
    // Needed to have the board URL for the iFrame
    console.log("localStorage boardUrlSSO: " + url);
    localStorage.setItem("boardUrlSSO", url);

    window.open(url, "_blank");
   };

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetchData()")

      // Ensure that the context information is correct/updated
      await clearCache() ; // Added for new code, was done in getIdToken()
      await getContext();

      // open the tab when clicked
      openInTab();

      /* const shareResult = await SharePermissions(
        localStorage.getItem("boardId") || ""
      );
      if (shareResult === false) {
        console.log("Error sharing permissions");
      } */
      return ;
    };

    fetchData();
  }, []); // Make sure to specify an empty dependency so that this happens only once

  return (
    <div>
     {
      <iframe
        title="Your KINAPS Board"
        src={`${localStorage.getItem("boardUrlSSO")}`}
        width="100%"
        height="100%"
        sandbox="allow-scripts allow-same-origin allow-top-navigation allow-forms allow-popups allow-pointer-lock allow-popups-to-escape-sandbox"
      ></iframe>
      }
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "30vh",
        }}
      >
        {/* 
        <Input
          type="text"
          value={customUrl}
          onChange={(e) => setCustomUrl(e.target.value)}
          placeholder="Enter Custom Board URL ..."
          style={{
            padding: "0.5rem",
            fontSize: "1.2rem",
          }}
        />
         */}
        <Button
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
          onClick={openInTab}
        >
          Open Current Board in New Tab
        </Button>
      </div>
       {/* 
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "30vh",
        }}
      >
        <Input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter Board ID ..."
          style={{
            padding: "0.5rem",
            fontSize: "1.2rem",
          }}
        />
        <Button
          onClick={handleClick}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
        >
          Share Permissions
        </Button>
      </div>
        */}
    </div>
  );
};

export default Tab;
