import React from "react";
import { JwtPayload } from "jsonwebtoken";

import RenderBoardConfigPage from "./BoardConfig";
import RenderInitialBoard from "./InitialBoard";
import RenderModifyBoardPage from "./OpenExistsBoard";
import RenderError from "./DisplayError";

import { getUser } from "../api/User.api";
import { clearCache, getContext, getTokenForSSO } from "../api/authUtils.api";


interface TabConfigProps {}

interface TabConfigState {
  nameOfBoard: string;
  currentPage: number;
  context: any;
  idToken: string;
  decodedToken: MyJwtPayload | null;
  APIpayload: string; // Add APIpayload to state
  boardType: string;
  errorMessage: string;
}

interface MyJwtPayload extends JwtPayload {
  email?: string;
}

class TabConfig extends React.Component<TabConfigProps, TabConfigState> {
  constructor(props: TabConfigProps) {
    super(props);
    this.state = {
      nameOfBoard: "",
      currentPage: 1,
      context: {},
      idToken: "",
      decodedToken: null,
      APIpayload: "",
      boardType: "",
      errorMessage: "",
    };

    this.setUserInfoToLocalStorage = this.setUserInfoToLocalStorage.bind(this);
  }

  // This function sets user information in the local storage based on the decoded ID token.
  private setUserInfoToLocalStorage(): void {
    localStorage.setItem("boardType", this.state.boardType);
  }

  // récupère le token brut stocké dans le localStorage et met à jour la page à jour (2)
  boardConfig = async () => {
    let token = ""

    // Retrieve the token
    console.log("getting id token");
    try {
        let res = await getTokenForSSO();

        if (!res) {
          console.log("Error while retrieving the token")
          this.setState({ currentPage: 4, errorMessage: 'Teams Plugin Error: Problem to retrieve a user token' });
          return ;
        }        

        // the token was retrieved, go on
        token = String(res)
    }
    catch (error) {
        // 25.11.24 debuging the token + environment variable kinapsAPIUrl
        // This needs the import:
        // import config from "./../api/config.ts";
        // this.setState({ currentPage: 4, errorMessage: 'getTokenForSSO() result test_11.25 (' + String(config.kinapsAPIUrl) + ') :' +  token });

        // 2.12.2024
        // If an error occurs, display it on the page, otherwise go on with the code 
        this.setState({ currentPage: 4, errorMessage: 'getTokenForSSO() result test_11.25B :' +  String(error) });
        return ;
        }   
    
    // Get and/or create user account
    console.log("getting user");
    const ownerData = await getUser(token);
    if (ownerData) {
      // Ensure that the context information is correct/updated
      await clearCache() ; // Added for new code, was done in getIdToken()
      localStorage.setItem("ownerID", ownerData._id);
      // Get context
      console.log("getting context");
      await getContext();
      const channelName = localStorage.getItem("channelName");
      const channelId = localStorage.getItem("channelId");
      const teamName = localStorage.getItem("teamName");
      const teamId = localStorage.getItem("teamId");
      console.log("channelName : ", channelName);
      console.log("channelId : ", channelId);
      console.log("teamName : ", teamName);
      console.log("teamId : ", teamId);
      this.setState({ currentPage: 2 });
     } else {
      console.log("Error while retrieving the user's informations")
      this.setState({ currentPage: 4, errorMessage: 'Kinaps Server Error: User not found (from token)' });
    }
  };

  modifyBoard = async () => {
    try {
      // Ensure that the context information is correct/updated
      await clearCache() ; // Added for new code, was done in getIdToken()      
      await getContext();
      this.setState({ currentPage: 3 });
    } catch (error) {
      console.log(
        "Erreur lors du déclenchement de la modification du tableau : ",
        error
      );
    }
  };

  // handleNameOfBoardChange is an event handler that updates the component state
  handleNameOfBoardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Update the nameOfBoard in the component state
    this.setState({ nameOfBoard: event.target.value });
  };

  private renderInitialBoard = (): React.ReactNode => (
    // Render the initial board with createBoard and modifyBoard callbacks
    <RenderInitialBoard
      createBoard={this.boardConfig}
      modifyBoard={this.modifyBoard}
    />
  );

  private renderError = (errorMessage: string): React.ReactNode => (
    // Render the initial board with createBoard and modifyBoard callbacks
    <RenderError
      errorMessage={errorMessage}
    />
  );

  private renderBoardConfigPage = (userName: string): React.ReactNode => (
    // Render the create board page with user name and handling of nameOfBoard change
    <RenderBoardConfigPage
      userName={userName}
      nameOfBoard={this.state.nameOfBoard}
      // boardType={boardType}
    />
  );

  private renderModifyBoardPage = (): React.ReactNode => (
    // Render the modify board page with a callback to go back to the tab setup
    
    <RenderModifyBoardPage
      goToTabSetup={() => this.setState({ currentPage: 2 })}
    />
  );

  render(): React.ReactNode {
    const { currentPage } = this.state;
    // Render different pages based on the current page state
    switch (currentPage) {
      case 1:
        return this.renderInitialBoard();
      case 2:
        const userName = localStorage.getItem("userGivenName") || "";
        return this.renderBoardConfigPage(userName);
      case 3:
        return this.renderModifyBoardPage();
      case 4:
        const { errorMessage } = this.state;
        return this.renderError(errorMessage);
      default:
        return null;
    }
  }
}
export default TabConfig;
