// https://fluentsite.z22.web.core.windows.net/quick-start
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { TeamsFxContext } from "./Context";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import TabConfig from "./TabConfig";
import Tab from "./Tab";
import * as microsoftTeams from "@microsoft/teams-js";
import "./App.css";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "./sample/lib/config";
import { FluentProvider } from "@fluentui/react-components";
import { useEffect } from "react";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  // Initialize the Microsoft Teams SDK
  const { theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  // To avoid the "No parent window" error, this code was proposed with useEffect
  // instead of the former simple call to 
  //   microsoftTeams.app.initialize();
  useEffect(() => {
    // Check if the Microsoft Teams SDK is available
    if (microsoftTeams) {
      microsoftTeams.app.initialize()
        .then(() => {
          console.log("Teams SDK initialized successfully");
        })
        .catch((error) => {
          console.error("Teams SDK initialization failed", error);
        });
    } else {
      console.error("App is not running inside Microsoft Teams");
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // Display the app home page hosted in Teams
  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential }}
    >
      <FluentProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/tab" element={<Tab />} />
            <Route path="/config" element={<TabConfig />} />
          </Routes>
        </BrowserRouter>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
