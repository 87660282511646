import React from "react";
import { Image, Button } from "@fluentui/react-components";

interface RenderInitialBoardProps {
  createBoard: () => void;
  modifyBoard: () => void;
}

const RenderInitialBoard: React.FC<RenderInitialBoardProps> = ({
  createBoard,
  modifyBoard,
}) => (
  <div className="tab-conf">
    <div className="logo-tab-conf py-30">
      <Image alt="logo kinaps" src="logo.png" />
    </div>
    <div className="display-column input-tab-config py-5">
      <Button shape="circular" onClick={createBoard}>
        Create a Board
      </Button>
    </div>
    <div className="display-column input-tab-config py-5">
      <Button shape="circular" onClick={modifyBoard}>
        Open an existing Board
      </Button>
    </div>
  </div>
);

export default RenderInitialBoard;
