import React, { useEffect } from "react";
import { Input, Image, Label, Text } from "@fluentui/react-components";
import { DocumentEdit24Regular } from "@fluentui/react-icons";
import * as microsoftTeams from "@microsoft/teams-js";
import { Select } from "@fluentui/react-components";
import {
  createMultiLayerBoard,
  createSingleLayerBoard,
} from "../api/Board.api";
import { Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";

interface RenderBoardConfigPageProps {
  userName: string;
  nameOfBoard: string;
}

const RenderBoardConfigPage: React.FC<RenderBoardConfigPageProps> = ({
  userName,
  nameOfBoard,
}) => {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [selectedBoardType, setSelectedBoardType] = React.useState(
    localStorage.getItem("boardType") || ""
  );
  const [inputValue, setInputValue] = React.useState(nameOfBoard);
  const [invalidInput, setInvalidInput] = React.useState(false);

  // Name of board input and board type selection in different hooks to avoid interaction problems
  useEffect(() => {
    setInvalidInput(inputValue.includes("%") || inputValue.trim() === "");
    localStorage.setItem("nameOfBoard", inputValue);
    console.log("nameofboard: ", localStorage.getItem("nameOfBoard"));
    microsoftTeams.pages.config.setValidityState(!invalidInput);
  }, [inputValue, invalidInput]);

  useEffect(() => {
    localStorage.setItem("boardType", selectedBoardType);
    console.log("selectedBoardType: ", localStorage.getItem("boardType"));
    microsoftTeams.pages.config.registerOnSaveHandler(async (saveEvent) => {
      const baseUrl = `https://${window.location.hostname}:${window.location.port}`;

      // If the user has selected SingleLayer Board, create it
      const boardType = localStorage.getItem("boardType") || "";
      if (boardType === "SingleLayer Board" || boardType === "") {
        console.log("Creating SingleLayer Board in BoardConfig.tsx");
        if (await createSingleLayerBoard()) {
          // Finish the configuration process
          // get the just created boardUrl
          const boardUrl = localStorage.getItem("boardUrl");

          microsoftTeams.pages.config.setConfig({
            suggestedDisplayName: localStorage.getItem("nameOfBoard") || "",
            entityId:  ``,
            contentUrl: `${baseUrl}/tab`,
            websiteUrl: `${boardUrl}`,
          });
          saveEvent.notifySuccess();
        }
        // If the user has selected MultiLayer Board, create it
      } else if (boardType === "MultiLayer Board") {
        console.log("Creating MultiLayer Board in BoardConfig.tsx");
        if (await createMultiLayerBoard()) {
          // Finish the configuration process
          // get the just created boardUrl
          const boardUrl = localStorage.getItem("boardUrl");
          // Use the boardUrl as entityId, websiteUrl and contentUrl
          microsoftTeams.pages.config.setConfig({
            suggestedDisplayName: localStorage.getItem("nameOfBoard") || "",
            entityId: ``,
            contentUrl: `${baseUrl}/tab`,
            websiteUrl: `${boardUrl}`,
          });

          saveEvent.notifySuccess();
          // If the chosen room name already exists, display an error message
        } else {
          if (localStorage.getItem("roomExists") === "true") {
            setDialogVisible(true);
            localStorage.setItem("roomExists", "false");
          }
          // Disable the save button to prevent the user from continuing
          saveEvent.notifyFailure();
        }
      }
    });
  }, [selectedBoardType]);

  return (
    <div className="tab-conf">
      <div className="logo-tab-conf py-30">
        <Image alt="logo kinaps" src="logo.png" />
      </div>
      <div className="display-column input-tab-config py-30">
        <h1>Welcome, {userName}!</h1>
        <Label className="py-5">
          <DocumentEdit24Regular />
          <Input
            placeholder="Name your Board..."
            type="text"
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
          />
        </Label>
        {invalidInput && (
          <Text style={{ color: "red" }}>
            The "%" character is forbidden and field cannot be empty.
          </Text>
        )}
        <div className="py-5">
          <div>
            <Select
              value={selectedBoardType}
              onChange={(event, data) => {
                setSelectedBoardType(data.value);
              }}
            >
              <option value="SingleLayer Board">SingleLayer Board</option>
              <option value="MultiLayer Board">MultiLayer Board</option>
            </Select>
          </div>
        </div>
      </div>
      <Dialog
        hidden={!dialogVisible}
        onDismiss={() => setDialogVisible(false)}
        dialogContentProps={{
          title: "Room Already Exists",
          subText:
            "A room with the same name already exists in this channel. Please choose another name.",
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={() => setDialogVisible(false)} text="OK" />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default RenderBoardConfigPage;
